import React from "react";
import { Link, graphql } from "gatsby";
import Layout from "../components/Layout";
import SEO from "../components/SEO";
import { formatDate } from "../helpers/formatters";
// import Breadcrumbs from "../components/Breadcrumbs";

export default function PostTemplate({ data }) {
    const { markdownRemark } = data;
    const { frontmatter, html, timeToRead, wordCount } = markdownRemark;
    // TODO: the breadcrumbs component doesn't work yet. Fix it later.
    // const breadcrumbPaths = [
    //     { path: "/", linkText: "Home" },
    //     { path: "/blog/", linkText: "Blog" },
    // ];
    // <Breadcrumbs paths={breadcrumbPaths} currentPage={frontmatter.title} />;

    return (
        <Layout>
            <SEO title={frontmatter.html_title || frontmatter.title} />
            <section className="section">
                <div className="container content">
                    <h1
                        className="title is-1"
                        dangerouslySetInnerHTML={{ __html: frontmatter.title }}
                    />
                    <div className="metadata">
                        <div className="read-time">
                            {timeToRead} minute read 🖖︎ {wordCount.words} words
                        </div>
                    </div>
                    <div
                        className="post-body"
                        dangerouslySetInnerHTML={{ __html: html }}
                    />
                    <div className="byline">
                        Posted {/*by {frontmatter.author}*/} on{" "}
                        {formatDate(frontmatter.created_at)}
                    </div>
                    <Link
                        style={{ marginTop: "17px" }}
                        className="button is-large"
                        to="/blog/"
                    >
                        Return to the Blog
                    </Link>
                </div>
            </section>
        </Layout>
    );
}

export const pageQuery = graphql`
    query ($path: String!) {
        markdownRemark(frontmatter: { path: { eq: $path } }) {
            frontmatter {
                title
                html_title
                author
                created_at
            }
            timeToRead
            html
            wordCount {
                words
                sentences
                paragraphs
            }
        }
    }
`;
