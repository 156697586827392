/**
 * This module contains formatting functions.
 */

/**
 * A date formatter for blog posts.
 */
const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
];

export function formatDate(dateString) {
    // I was having trouble getting the correct date, even with the
    // gatsby plugin that sets the timezone to America/Los_Angeles
    // during build. This hack works for now.
    const pattern = /(\d{4})-(\d{2})-(\d{2})/;
    const [year, month, day] = dateString.match(pattern).slice(1);
    return `${months[Number(month) - 1]} ${day}, ${year}`;
}
